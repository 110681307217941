/**
 * @file 应用相关接口
 */
import { URL } from 'scripts/constants/index'
import reqApi from './reqApi'
/**
 * 接口封装
 * @param {String} url - 请求路径
 * @param {String} method - 请求方式 只支持get/post
 * @param {Object} params - 请求参数
 * @param {String} that - 全局对象 this 的传递，为做页面跳转
 */

export default {

    /**
     * 新建/编辑 应用
     */
    create(params, that) {
        return reqApi.reqApi('/auth/app/save', 'post', params, that)
        .then(res => res)
        .catch(err => err)
    },

    /**
     * 删除应用
     * @param {Number} id - 应用 ID
     */
    delete(params, that) {
        return reqApi.reqApi('/auth/app/remove', 'post', params, that)
        .then(res => res)
        .catch(err => err)
    },

    /**
     * 获取应用列表
     * @param {Object} params - 请求参数
     * @param {Number} params.from - 从第几条开始
     * @param {Number} params.size - 请求几条
     * @param {String} [params.name] - 搜索关键词
     */
    getList(params, that) {
        let obj = {}
        if (URL.ISDEV) {
            obj['LC-OA-Auth-User-ID'] = 1
        }
        return reqApi.reqApi('/auth/app/list', 'get', params, that, obj)
        .then(res => {
            if (res && res.data && res.data.status == 0) {
                return res.data.data
            } else {
                throw res.data
            }
        })
    },
}