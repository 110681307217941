/**
 * @file Axios 全局配置
 */
import axios from 'axios'
import { URL } from '../constants/index'

export default axios.create({
  baseURL: URL.HOSTNAME,
  withCredentials: true,
})
