/**
 * @file 常量 - 权限系统
 */
export default {
  APPID: 21,
    // process.env.NODE_ENV != 'production' || /tmoss/.test(location.hostname)
    //   ? 21
    //   : 7,
  MODULE: {
    PRODUCT: {
      ADMIN: 'product.admin',
    },
  },
}
