import React from 'react'
import { withRouter, Link, Route, Switch } from 'react-router-dom'
import {
  Avatar,
  Dropdown,
  Layout,
  Menu,
  message,
  Button,
  Select,
  Space,
} from 'antd'
import { reqStaff } from 'scripts/requests/index'
import {
  LogoutOutlined,
  SecurityScanOutlined,
  UserOutlined,
  CaretDownOutlined,
  DownOutlined,
} from '@ant-design/icons'
import LcoaHead from 'lcoa-react-antd/dist/index'

import './index.postcss'

const Manage = React.lazy(() => import('modules/manage/index'))
const ManageApp = React.lazy(() => import('modules/manage/app/index'))
const NoMatch = React.lazy(() => import('modules/404/index'))
const Login = React.lazy(() => import('modules/login/index'))
const Password = React.lazy(() => import('modules/password/index'))
const Binding = React.lazy(() => import('modules/binding/index'))
const Logs = React.lazy(() => import('modules/logs/index'))

class App extends React.Component {
  constructor(props) {
    super(props)
    let selectedKeys = props.location.pathname.match(/^\/[^\/]*/)[0]
    this.state = {
      userInfo: {},
      app_id: 1,

      selectedKeys: [selectedKeys],
      menu: [],
    }
  }

  logout = () => {
    // 退出登录，清除对应cookie
    document.cookie =
      'auth-session=out; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    this.props.history.push('/login')
    return
    let cookieArr = document.cookie.split('; ')
    Array.isArray(cookieArr) &&
      cookieArr.map((item) => {
        let arr = item.split('=')
        if ((arr[0] = 'auth-session')) {
          let exp = new Date()
          exp.setTime(exp.getTime() - 1)
          document.cookie = `auth-session=${arr[1]};expires=-1;path=/`
          this.props.history.push('/login')
        }
      })
    //
  }

  getMenu = () => {
    // reqAuth
    //   .queryAuth({
    //     privileges: [
    //       {
    //         domain: 'auth',
    //         resource: AUTH.MODULE.PRODUCT.ADMIN,
    //       },
    //     ],
    //   })
    //   .then(result => {
    //     const { privileges = [] } = result
    //     let menu = [
    //       {
    //         route: `/`,
    //         name: '权限申请',
    //       },
    //     ]
    //     if (privileges[0] && privileges[0].allow) {
    //       menu = menu.concat([
    //         {
    //           route: `/approval`,
    //           name: '权限审批',
    //         },
    //         {
    //           route: `/manage`,
    //           name: '权限管理',
    //         },
    //       ])
    //     }
    //     menu.push({
    //       route: '/docs',
    //       name: '使用手册',
    //       link: `https://moss.oa.com/docs/`,
    //     })
    //     this.setState({
    //       menu,
    //     })
    //   })
  }

  componentDidMount() {
    this.getMenu()
    if (location.pathname == '/auth' || location.pathname == '/auth/') {
      this.props.history.replace('/manage')
    }
    if (/manage/.test(location.pathname) || /logs/.test(location.pathname)) {
      new Promise((resolve, reject) => {
        this.initAppid(resolve)
      }).then(() => {
        this.getMyInfo()
      })
    }
  }

  getMyInfo = () => {
    let params = {
      app_id: Number(this.state.app_id),
    }
    reqStaff.getMyInfo(params, this).then((res) => {
      if (res && res.data && res.data.status == 0) {
        // res.data.data &&
        //   res.data.data.app_out &&
        //   res.data.data.app_out.map((item) => {
        //     item.web_folder = location.origin + '/' + item.web_folder
        //   })
        this.setState({
          userInfo: res.data.data || {},
        })
      } else {
        message.error(res.data.msg)
      }
    })
  }

  initAppid = (resolve) => {
    let propSearch = this.props.location.search
    let app_id = ''
    if (propSearch && propSearch != '') {
      let arr = propSearch.split('&')
      arr &&
        arr.map((item) => {
          if (/appid/.test(item)) {
            app_id = item.split('=')[1]
          }
        })
    } else {
      app_id = 1
    }
    this.setState(
      {
        app_id,
      },
      () => {
        resolve()
      }
    )
  }

  // 返回上一级按钮
  goBack = () => {
    this.props.history.goBack()
  }
  // 跳转应用事件
  // toApp = (val) => {
  //   window.location.href = val
  // }
  gotoManage = () => {
    window.location.href = '/auth/manage'
  }

  // https://toa.luckyxp.cn/auth
  // /auth

  render() {
    const { userInfo } = this.state
    return (
      <Layout className='ma-app'>
        {userInfo.id ? (
          <LcoaHead
            title='权限系统'
            logo='https://lc-common-data.oss-cn-shenzhen.aliyuncs.com/img/logo.svg'
            gotourl='/auth/manage'
            regexp='manage'
            appid={1}
            userInfo={userInfo}
          />
        ) : (
          <Layout.Header>
            <div className='title' onClick={this.gotoManage}>
              <div className='logo'>
                <img src='https://lc-common-data.oss-cn-shenzhen.aliyuncs.com/img/logo.svg' />
              </div>
              <h1>权限系统</h1>
            </div>
            {location.pathname == '/auth/logs' && (
              <div>
                <Button type='primary' onClick={this.goBack}>
                  返回上一级
                </Button>
              </div>
            )}
          </Layout.Header>
        )}

        {/* <Layout.Header style={{ display: 'none' }}>
          <div className='app-header-wrap'>
            <div>
              <Link className='title' to={'/manage'}>
                <div className='logo'></div>
                <h1>权限系统</h1>
              </Link>
            </div>
            {location.pathname == '/auth/logs' && (
              <div>
                <Button type='primary' onClick={this.goBack}>
                  返回上一级
                </Button>
              </div>
            )}
            {/manage/.test(location.pathname) && (
              <div className='user_info'>
                <div className='select_app'>
                  <Button type='link' className='select_app_btn'>
                    <Space size={0}>
                      跳转应用
                      <DownOutlined className='downLined' />
                    </Space>
                  </Button>
                  <div className='select_app_wrap'>
                    <div className='select_app_title'>选择需要跳转的应用</div>
                    {userInfo.app_out &&
                      userInfo.app_out.map((item, index) => {
                        return (
                          <div
                            onClick={this.toApp.bind(this, item.web_folder)}
                            key={index}
                            className='select_app_box'>
                            {item.name}
                          </div>
                        )
                      })}
                  </div>
                </div>
                <div className='user_info_head'>
                  <Avatar src={userInfo.avatar} icon={<UserOutlined />} />
                </div>
                <div className='user_info_center'>
                  <p>{userInfo.name || 'guest'}</p>
                  <p>用户ID：{userInfo.id}</p>
                </div>
                <div className='user_info_drop'>
                  <Dropdown
                    className='dropdown'
                    trigger={['click']}
                    overlay={
                      <Menu className='ma-app-dropdown-menu'>
                        <Menu.Item>
                          <Link to='/logs'>
                            <SecurityScanOutlined className='icon' />
                            个人日志
                          </Link>
                        </Menu.Item>
                        <Menu.Item onClick={this.logout}>
                          <LogoutOutlined className='icon' />
                          退出登录
                        </Menu.Item>
                      </Menu>
                    }
                    placement='bottomRight'>
                    <div>
                      <CaretDownOutlined className='drop_btn' />
                    </div>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
        </Layout.Header> */}

        <React.Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path={`/`} component={ManageApp} />
            <Route exact path={`/login`} component={Login} />
            <Route exact path={`/password`} component={Password} />
            <Route exact path={`/binding`} component={Binding} />
            <Route
              exact
              path={`/logs`}
              component={(props) => <Logs {...props} userInfo={userInfo} />}
            />
            <Route
              exact
              path={`/manage`}
              component={(props) => (
                <ManageApp {...props} userInfo={userInfo} />
              )}
            />
            <Route path={`/manage`} component={Manage} />
            <Route component={NoMatch} />
          </Switch>
        </React.Suspense>
      </Layout>
    )
  }
}

const AppRouter = withRouter(App)

export default AppRouter
