/**
 * @file 员工相关接口
 */
import axios from './axios'
import { URL } from 'scripts/constants/index'
import reqApi from './reqApi'
/**
 * 接口封装
 * @param {String} url - 请求路径
 * @param {String} method - 请求方式 只支持get/post
 * @param {Object} params - 请求参数
 * @param {String} that - 全局对象 this 的传递，为做页面跳转
 */

export default {
    
    /**
     * 获取我的信息
     */
    getMyInfo(params, that) {
        let obj = {}
        if (URL.ISDEV) {
            obj['LC-OA-Auth-User-ID'] = 406
            params.app_id = 2
        }
        return reqApi.reqApi('/auth/out/role/get', 'get', params, that, obj)
        .then(res => res)
        .catch(err => err)
    },
}