/**
 * @file 角色相关接口
 */
import reqApi from './reqApi'
import { message } from 'antd'
/**
 * 接口封装
 * @param {String} url - 请求路径
 * @param {String} method - 请求方式 只支持get/post
 * @param {Object} params - 请求参数
 * @param {String} that - 全局对象 this 的传递，为做页面跳转
 */
export default {
  /**
   * 获取用户列表
   * @param {Object} params - 请求参数
   * @param {Number} params.app_id - 应用 ID
   * @param {Number} params.from - 从第几个开始
   * @param {Number} params.size - 请求个数
   * @param {String} [params.name] - 名称搜索关键词
   * @param {String} [params.domain_id] - 产品 ID（和 name 同时查询时，被忽略）
   * @param {String} [params.role_id] - 角色 ID（和 name 同时查询时，被忽略）
   */
  getList(params, that) {
    return reqApi
      .reqApi('/auth/user/list', 'get', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 新增/编辑用户
  saveUser(params, that) {
    return reqApi
      .reqApi('/auth/user/save', 'post', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 删除用户
  removeUser(params, that) {
    return reqApi
      .reqApi('/auth/user/remove', 'post', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 用户角色保存/更新
  userRoleSave(params, type, that) {
    let url = ''
    if (type == 'add') {
      url = '/auth/user/role/create'
    } else {
      url = '/auth/user/role/update'
    }
    return reqApi
      .reqApi(url, 'post', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 删除用户角色
  removeUserRole(params, that) {
    return reqApi
      .reqApi('/auth/user/role/remove', 'post', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 激活/冻结用户
  userBan(params, that) {
    return reqApi
      .reqApi('/auth/user/ban', 'post', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 用户审批流页面接口
  // 审批流列表
  approvalList(params, that) {
    return reqApi
      .reqApi('/approve/list', 'get', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 2021-09-08 新增 审批详情接口
  approvalStatus(params, that) {
    return reqApi
      .reqApi('/approve/status', 'get', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 添加/编辑审批流
  addApproval(params, type, that) {
    let url = ''
    if (type == 'edit') {
      url = '/approve/update'
    } else {
      url = '/approve/create'
    }
    return reqApi
      .reqApi(url, 'post', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 查看审批流详情
  seeApproval(params, that) {
    return reqApi
      .reqApi('/approve/in/get', 'get', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 删除审批流列表
  delApproval(params, that) {
    return reqApi
      .reqApi('/approve/remove', 'post', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 根据钉钉全量拉取并更新用户
  ddUpdate(params, that) {
    return reqApi
      .reqApi('/auth/user/dingding/update', 'post', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  // 审批流统计
  getStatistical(params, that) {
    return reqApi
      .reqApi('/approve/flow/statistical', 'get', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  //生成用户 token
  getUserToken(params, that) {
    return reqApi
      .reqApi('/auth/user/token', 'get', params, that)
      .then((res) => res)
      .catch((err) => err)
  },

  getLogList(params, that) {
    return reqApi
      .reqApi('/auth/user/token_log', 'get', params, that)
      .then((res) => res)
      .catch((err) => err)
  },
}
