/**
 * @file 入口文件
 */
import React, { useState, useEffect, createRef, forwardRef } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import zhCN from 'antd/es/locale/zh_CN'

import 'antd/dist/antd.css'
import 'styles/index.postcss'

import App from 'modules/app/index'

ReactDOM.render(
  <BrowserRouter basename='/auth'>
    <ConfigProvider locale={zhCN}>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
