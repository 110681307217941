/**
 * @file 权限相关接口
 */
import reqApi from './reqApi'
/**
 * 接口封装
 * @param {String} url - 请求路径
 * @param {String} method - 请求方式 只支持get/post
 * @param {Object} params - 请求参数
 * @param {String} that - 全局对象 this 的传递，为做页面跳转
 */

export default {
    create(params, that) {
        return reqApi.reqApi('/auth/right/save', 'post', params, that)
        .then(res => res)
        .catch(err => err)
    },

    /**
     * 删除单据
     * @param {Number} id - 单据 ID
     */
    delete(params, that) {
        return reqApi.reqApi('/auth/right/remove', 'post', params, that)
        .then(res => res)
        .catch(err => err)
    },

    /**
     * 获取权限列表
     */
    getList(params, that) {
        return reqApi.reqApi('/auth/right/get', 'get', params, that)
        .then(res => res)
        .catch(err => err)
    },
}