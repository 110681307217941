/**
 * @file 接口 - 登录
 */
import axios from './axios'
import { message } from 'antd'

export default {
  logout() {
    axios.get('/auth/api/client/gateway/logout')
  },

  login(params) {
    return axios.post('/auth/out/login', params)
    .then(res => {
      if (res && res.data && res.data.status == 0) {
        return res.data
      } else {
        message.error(res.data.msg)
        return undefined
      }
    })
    .catch(err => {
      message.error(err.msg)
      return undefined
    })
  },

  // 手机短信登录，防止机器人刷短信
  isRobot(params) {
    let fd = new FormData()
    fd.append('phone', params.phone)
    return axios.post('/auth/out/get_robot', fd)
    .then(res => {
      if (res && res.data && res.data.status == 0) {
        return res.data.data
      } else {
        return undefined
      }
    })
    .catch(err => {
      message.error(err.data.msg)
      return undefined
    })
  },

  // 获取短信验证码
  getShortCode(params) {
    let fd = new FormData()
    fd.append('phone', params.phone)
    if (params.code) {
      fd.append('code', params.code)
    }
    return axios.post('/auth/out/phone_code', fd)
    .then(res => res)
  },

  // 修改密码
  resetPass(params) {
    return axios.post('/auth/out/pass_reset', params)
    .then(res => res)
    .catch(err => {
      message.success(err.msg)
    })
  },

  // QQ登录,绑定手机号
  bindPhone(params) {
    return axios.post('/auth/out/register', params)
    .then(res => res)
    .catch(err => {
      message.error(err.msg)
    })
  },
}
