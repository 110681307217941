/**
 * @file 角色相关接口
 */
import reqApi from './reqApi'
/**
 * 接口封装
 * @param {String} url - 请求路径
 * @param {String} method - 请求方式 只支持get/post
 * @param {Object} params - 请求参数
 * @param {String} that - 全局对象 this 的传递，为做页面跳转
 */
export default {
  /**
   * 获取角色列表
   * @param {Object} params - 请求参数
   * @param {Number} params.app_id - 应用 ID
   * @param {Number} params.from - 从第几个开始
   * @param {String} params.size - 请求个数
   * @param {String} [params.keyword] - 关键词
   * @param {Number} [params.user_id] - 用户 ID
   */
  getList(params, that) {
    return reqApi.reqApi('/auth/role/list', 'get', params, that)
    .then(res => res)
    .catch(err => err)
  },

  // 保存角色
  saveRole(params, that) {
    return reqApi.reqApi('/auth/role/save', 'post', params, that)
    .then(res => res)
    .catch(err => err)
  },

  // 权限分配
  authAss(params, that) {
    return reqApi.reqApi('/auth/role/right/save', 'post', params, that)
    .then(res => res)
    .catch(err => err)
  },

  // 删除角色
  removeRole(params, that) {
    return reqApi.reqApi('/auth/role/remove', 'post', params, that)
    .then(res => res)
    .catch(err => err)
  }
}
