import axios from './axios'
import { message, notification } from'antd'

export default {
  /**
   * 接口报错统一跳转 login，视为登录态失效
   * @param {String} url - 请求路径
   * @param {String} method - 请求方式 只支持get/post
   * @param {Object} params - 请求参数
   * @param {String} that - 全局对象 this 的传递，为做页面跳转
   */
  reqApi (url, method, params, that, obj = {}) {
    let loginUrl = '/login'
    if (that) {
      let basrUrl = location.href
      loginUrl += `?redirect_uri=${encodeURIComponent(basrUrl)}`
    }
    if (method == 'get') {
      return axios.get(url, { 
        params,
        headers: {
          ...obj
        }
       })
      .then(res => res)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status == 401) {
            message.destroy()
            message.error('登录已过期，请重新登录')
            that && that.props.history.push(loginUrl)
          } else if (error.response.status == 403) {
            message.destroy()
            message.error('没有权限进行当前操作')
          }
        } else {
          message.destroy()
          message.error('登录已过期，请重新登录')
          that && that.props.history.push(loginUrl)
        }
        return error
      })
    } else {
      return axios.post(url, params)
      .then(res => res)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status == 401) {
            message.destroy()
            message.error('登录已过期，请重新登录')
            that && that.props.history.push(loginUrl)
          } else if (error.response.status == 403) {
            message.destroy()
            message.error('没有权限进行当前操作')
          }
        } else {
          message.destroy()
          message.error('登录已过期，请重新登录')
          that && that.props.history.push(loginUrl)
        }
        return error
      })
    }
  }
}
