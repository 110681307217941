/**
 * @file 常量 - URL
 */

// let hostname = 'http://oamy.luckyxp.cn'
let hostname = '' //全局请求前置url
let isDevelopment = false //是否为本地开发环境
let state = encodeURIComponent('/manage') //无参数时候,默认跳转
let search = location.search //路由携带的参数
let redirect_uri = ''
if (search && search != '') {
  state = search.split('=')[1]
}
let client_id = ''

let ddTalkUrl = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=` //钉钉登录二维码路由
let ddLoginURL = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=` //钉钉扫码确认回调跳转路由

if (/^oa\.luckyxp\.cn$/.test(location.hostname)) {
  hostname += 'https://oa.luckyxp.cn'
  ddTalkUrl += `dingoapoogqjt0luart7ff&response_type=code&scope=snsapi_login&state=${state}&redirect_uri=https://oa.luckyxp.cn/auth_api/auth/ding_callback`
  ddLoginURL += `dingoapoogqjt0luart7ff&response_type=code&scope=snsapi_login&state=${state}&redirect_uri=https://oa.luckyxp.cn/auth_api/auth/ding_callback&loginTmpCode=`
  isDevelopment = false
  redirect_uri = encodeURIComponent(
    'https://oa.luckyxp.cn/auth_api/auth/qq_callback'
  )
  client_id = '101921716'
} else if (/^pre\.luckyxp\.cn$/.test(location.hostname)) {
  hostname += 'https://pre.luckyxp.cn'
  ddTalkUrl += `dingoamv2xrsu7sejijrv0&response_type=code&scope=snsapi_login&state=${state}&redirect_uri=https://pre.luckyxp.cn/auth_api/auth/ding_callback`
  ddLoginURL += `dingoamv2xrsu7sejijrv0&response_type=code&scope=snsapi_login&state=${state}&redirect_uri=https://pre.luckyxp.cn/auth_api/auth/ding_callback&loginTmpCode=`
  isDevelopment = false
  redirect_uri = encodeURIComponent(
    'https://pre.luckyxp.cn/auth_api/auth/qq_callback'
  )
  client_id = '101921716'
} else if (/^toa\.luckyxp\.cn$/.test(location.hostname)) {
  hostname += 'https://toa.luckyxp.cn'
  ddTalkUrl += `dingoarvnxzdz0xdgow9xc&response_type=code&scope=snsapi_login&state=${state}&redirect_uri=https://toa.luckyxp.cn/auth_api/auth/ding_callback`
  ddLoginURL += `dingoarvnxzdz0xdgow9xc&response_type=code&scope=snsapi_login&state=${state}&redirect_uri=https://toa.luckyxp.cn/auth_api/auth/ding_callback&loginTmpCode=`
  isDevelopment = false
  redirect_uri = encodeURIComponent(
    'https://toa.luckyxp.cn/auth_api/auth/qq_callback'
  )
  client_id = '101921716'
} else {
  // hostname = 'http://moss.qq.com'
  hostname = 'https://toa.luckyxp.cn'
  ddTalkUrl += `dingoalmzdmxck7oh0mnkn&response_type=code&scope=snsapi_login&state=${state}&redirect_uri=http://test.toa.luckyxp.cn/auth/ding_callback`
  ddLoginURL += `dingoalmzdmxck7oh0mnkn&response_type=code&scope=snsapi_login&state=${state}&redirect_uri=http://test.toa.luckyxp.cn/auth/ding_callback&loginTmpCode=`
  isDevelopment = false
  redirect_uri = encodeURIComponent('http://moss.qq.com/match/manager/oauth')
  client_id = '101491536'
}
let baseurl = hostname
if (!isDevelopment) {
  hostname += '/auth_api'
}
// hostname += '/auth_api'

export default {
  HOSTNAME: hostname,
  DDTALKURL: ddTalkUrl,
  DDLOGINURL: ddLoginURL,
  ISDEV: isDevelopment,
  REDURL: redirect_uri,
  CLIENTID: client_id,
  BASEURL: baseurl,
}
