/**
 * @file 应用相关接口
 */
import reqApi from './reqApi'
/**
 * 接口封装
 * @param {String} url - 请求路径
 * @param {String} method - 请求方式 只支持get/post
 * @param {Object} params - 请求参数
 * @param {String} that - 全局对象 this 的传递，为做页面跳转
 */

export default {
    /**
     * 新建/编辑 应用
     */
    create(params, that) {
        return reqApi.reqApi('/auth/resource/add', 'post', params, that)
            .then(res => res)
            .catch(err => err)
    },

    /**
     * 编辑 应用
     */
    update(params, that) {
        return reqApi.reqApi('/auth/resource/update', 'post', params, that)
            .then(res => res)
            .catch(err => err)
    },

    /**
     * 删除应用
     * @param {Number} id - 应用 ID
     */
    delete(params, that) {
        return reqApi.reqApi('/auth/resource/remove', 'post', params, that)
            .then(res => res)
            .catch(err => err)
    },

    /**
     * 获取应用列表
     * @param {Object} params - 请求参数
     * @param {Number} params.from - 从第几条开始
     * @param {Number} params.size - 请求几条
     * @param {String} [params.name] - 搜索关键词
     */
    getList(params, that) {
        return reqApi.reqApi('/auth/resource/list', 'get', params, that)
            .then(res => {
                if (res.data.status == 0) {
                    return res.data.data
                } else {
                    throw res.data
                }
            })
    },

    // 资源列表（树结构）
    getTreeList(params, that) {
        return reqApi.reqApi('/auth/resource/tree', 'get', params, that)
            .then(res => {
                if (res.data.status == 0) {
                    return res.data.data
                } else {
                    throw res.data
                }
            })
    },

    // 获取部门结构
    structList() {
        return reqApi.reqApi('/auth/in/department/struct', 'get')
            .then(res => {
                if (res.data.status == 0) {
                    return res.data.data
                } else {
                    throw res.data
                }
            })
    },

    // 获取部门用户
    getUser(params, that) {
        return reqApi.reqApi('/auth/in/department/user_get', 'get', params, that)
            .then(res => {
                if (res.data.status == 0) {
                    return res.data.data
                } else {
                    throw res.data
                }
            })
    },
}