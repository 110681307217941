/**
 * @file 应用相关接口
 */
import { URL } from 'scripts/constants/index'
import reqApi from './reqApi'
/**
 * 接口封装
 * @param {String} url - 请求路径
 * @param {String} method - 请求方式 只支持get/post
 * @param {Object} params - 请求参数
 * @param {String} that - 全局对象 this 的传递，为做页面跳转
 */

export default {
  /**
   * 日志列表 外部用户
   */
  getLogList(params, that) {
    let obj = {}
    if (URL.ISDEV) {
      obj['LC-OA-Auth-User-ID'] = 1
    }
    return reqApi
      .reqApi('/auth/in/transport_log', 'get', params, that, obj)
      .then((res) => res)
      .catch((err) => err)
  },

  // 日志列表 内部管理员查看
  allLogList(params, that) {
    return reqApi
      .reqApi('/auth/transport/list', 'get', params, that)
      .then((res) => res)
      .catch((err) => err)
  },
}
